import IconButton from "@material-ui/core/IconButton";
import Popper from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";
import * as React from "react";

import styled from "~/components/core/styled";
import { ISubject, Subject, SubjectGroup } from "~/declarations/models/Subject";
import { isEmpty, isEquals } from "~/utils/common";
import { trackEvent } from "~/utils/segment";
import { useIfMounted } from "~/utils/useIfMounted";

import SubjectHierarchyView from "./SubjectHierarchyView";
import { useSearchFilter } from "./useSearchFilter";

export interface Props {
  subjects?: SubjectGroup[];
  initialSubjects: number[] | null;
  loading?: boolean;
  disabled?: boolean;
  onSelect?: (subjects: ISubject[]) => void;
  onSelectGroup?: (subjectGroup: ISubject) => void;
  multiselect?: boolean;
}

const STUB_STRING = "Nothing select";

function selectedStringFromSubjects(
  subjects: ISubject[],
  fallbackString: string = STUB_STRING
): string {
  if (isEmpty(subjects)) return fallbackString;

  const firstSubjectName = subjects[0]?.name;

  if (subjects.length > 1) {
    const moreCount = `+${subjects.length - 1}`;
    return `${firstSubjectName} ...(${moreCount})`;
  } else {
    return firstSubjectName;
  }
}

const SubjectSelect: React.FC<Props> = ({
  loading,
  multiselect,
  initialSubjects,
  onSelect,
  onSelectGroup,
  disabled = false,
  subjects = []
}: Props): JSX.Element => {
  const ifMounted = useIfMounted();
  const textFieldRef = React.useRef<HTMLInputElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [selectedSubjects, setSelectedSubjects] = React.useState<ISubject[]>(
    []
  );
  const [fieldValue, setFieldValue] = React.useState(subjects[0]?.name);
  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null);
  const [inputTouched, setInputTouched] = React.useState(false);
  const subjectsToSelect = useSearchFilter(fieldValue, inputTouched, subjects);
  const [initiallySet, setInitiallySet] = React.useState(false);
  const [consumedInitialSubjects, setConsumedInitialSubjects] = React.useState<
    number[] | null
  >([]);
  const listOpened = Boolean(anchorEl);

  React.useEffect(() => {
    if (!isEmpty(subjects)) {
      setFieldValue(subjects[0].name);
    }
  }, [subjects]);

  React.useEffect(() => {
    if (!isEquals(initialSubjects, consumedInitialSubjects)) {
      setInitiallySet(false);
    }
  }, [consumedInitialSubjects, initialSubjects, selectedSubjects]);

  React.useEffect(() => {
    if (!initiallySet && !isEmpty(subjects)) {
      const foundSubjects: Subject[] = [];

      initialSubjects?.forEach(initSubjId => {
        let foundSubject: Subject | undefined;

        subjects.every(({ children }) => {
          children?.every(subject => {
            if (subject.id === initSubjId) {
              foundSubject = subject;
              return false;
            }
            return true;
          });

          if (foundSubject) {
            foundSubjects.push(foundSubject);
            return false;
          }

          return true;
        });
      });

      if (!isEmpty(foundSubjects)) {
        setSelectedSubjects(foundSubjects);
        setFieldValue(selectedStringFromSubjects(foundSubjects));
      } else {
        setFieldValue(subjects[0].name);
        setSelectedSubjects([]);
      }

      setInitiallySet(true);
      setConsumedInitialSubjects(initialSubjects);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickOpenList = (
    event: React.MouseEvent<HTMLInputElement>
  ): void => {
    if (disabled) return;

    setAnchorEl(event?.currentTarget);

    const inputControl = inputRef?.current;

    if (inputControl) {
      window.setTimeout((): void => {
        ifMounted(() => {
          inputControl.focus();
          inputControl.setSelectionRange(0, inputControl.value.length);
        });
      }, 500);
    }
  };

  const handleCloseList = (newSubjects: ISubject[]): void => {
    setAnchorEl(null);
    setInputTouched(false);
    setSelectedSubjects(newSubjects);
    setFieldValue(selectedStringFromSubjects(newSubjects, subjects[0]?.name));
  };

  const handleFocus = (): void => {
    window.setTimeout((): void => {
      ifMounted(() => {
        if (!listOpened) {
          setAnchorEl(textFieldRef.current);
        }
      });
    }, 200);
  };

  const handleChangeTextField = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFieldValue(event.target.value);
    setInputTouched(true);
  };

  if (loading) {
    return <SubjectSelectSkeleton />;
  }

  return (
    <Wrapper>
      <TextField
        ref={textFieldRef}
        inputRef={inputRef}
        variant="outlined"
        value={fieldValue ? fieldValue : ""}
        onChange={handleChangeTextField}
        onClick={handleClickOpenList}
        onFocus={handleFocus}
        disabled={disabled}
        fullWidth
        InputProps={{
          margin: "dense",
          endAdornment: (
            <EndAdornmentButton size="small" tabIndex={-1} disabled={disabled}>
              {listOpened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </EndAdornmentButton>
          )
        }}
      />
      <StyledPopper open={listOpened} anchorEl={anchorEl}>
        <SubjectHierarchyView
          multiselect={multiselect}
          subjects={subjectsToSelect}
          initSelectedSubjects={selectedSubjects}
          onClose={handleCloseList}
          onSelect={onSelect}
          onSelectGroup={onSelectGroup}
        />
      </StyledPopper>
    </Wrapper>
  );
};

export default SubjectSelect;

const Wrapper = styled.div`
  font-size: 13px;
  min-width: 220px;

  input {
    font-size: 13px;
    text-transform: uppercase;
  }
`;

const StyledPopper = styled(Popper)`
  z-index: ${({ theme }): number => theme.zIndex.modal};
`;

const SubjectSelectSkeleton = styled(Skeleton)`
  border-radius: 20px;
  height: 38px;
  min-width: 240px;
  transform: unset;
`;

const EndAdornmentButton = styled(IconButton)`
  margin-right: -9px;
`;
