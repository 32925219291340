import {
  Button,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Popover,
  Radio,
  Typography
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import * as React from "react";

import styled from "~/components/core/styled";
import { DropDownFilterItem } from "~/constants/filters";

interface DropDownFilterProps {
  onChange: (item: DropDownFilterItem) => void;
  items: DropDownFilterItem[];
  disabledValues?: string[];
  selectedItem: DropDownFilterItem;
}

const DropDownFilter: React.FC<DropDownFilterProps> = ({
  onChange,
  disabledValues,
  items,
  selectedItem
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClickItem = (item: DropDownFilterItem) => (): void => {
    onChange(item);
    setAnchorEl(null);
  };

  return (
    <>
      <StyledButton
        variant="outlined"
        endIcon={<ExpandMore />}
        onClick={handleOpen}
      >
        {selectedItem.label}
      </StyledButton>

      <StyledPopover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <StyledList>
          {items.map(item => {
            const { value, label, description } = item;
            const disabled = disabledValues?.includes(value);

            return (
              <ListItem
                key={value}
                onClick={
                  disabled
                    ? undefined
                    : ev => {
                        ev.stopPropagation();
                        handleClickItem(item)();
                      }
                }
                disabled={disabled}
              >
                <StyledListItemText
                  primary={
                    <FormControlLabel
                      value={value}
                      control={
                        <Radio
                          color="primary"
                          checked={value === selectedItem.value}
                          disabled={disabled}
                        />
                      }
                      label={<Caption variant="body1">{label}</Caption>}
                    />
                  }
                  secondary={
                    !!description ? (
                      <Description component="span">{description}</Description>
                    ) : (
                      undefined
                    )
                  }
                />
              </ListItem>
            );
          })}
        </StyledList>
      </StyledPopover>
    </>
  );
};

export default DropDownFilter;

const StyledPopover = styled(Popover)`
  margin-top: 8px;
`;

const StyledButton = styled(Button)`
  border-color: ${({ theme }): string => theme.palette.grey[400]};
  border-radius: 22px;
  font-size: 13px;
  height: 38px;
  white-space: nowrap;
`;

const Caption = styled(Typography)`
  text-transform: uppercase;
`;

const Description = styled(Typography)<{ component?: React.ElementType }>`
  display: inline-block;
  font-size: 10px;
  margin-left: 31px;
  max-width: 225px;
`;

const StyledList = styled(List)`
  > *:not(:last-child) {
    border-bottom: 1px solid ${({ theme }): string => theme.palette.grey[200]};
  }
`;

const StyledListItemText = styled(ListItemText)`
  pointer-events: none;
  user-select: none;
`;
